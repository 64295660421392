import React, { forwardRef, ReactNode } from 'react';

import { CSS, styled } from '@/stitches.config';

const ContainerStyled = styled('div', {
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  variants: {
    size: {
      sm: {
        px: '$space-3',
        '@sm': {
          px: '$space-6',
        },
      },
      md: {
        px: '$space-6',
        '@sm': {
          px: '$space-12',
        },
      },
      'md-fixed': {
        px: '$space-6',
      },
      lg: {
        px: '$space-12',
      },
      'desktop-only': {
        '@lg': {
          px: '$space-12',
        },
      },
      'mobile-only': {
        px: '$space-6',
        '@sm': {
          px: '$space-12',
        },
        '@lg': {
          px: '$space-0',
        },
      },
      none: { px: '$space-0' },
    },
    maxWidth: {
      none: {
        maxWidth: 'none',
      },
      '2xs': {
        maxWidth: '384px',
      },
      xs: {
        maxWidth: '640px',
      },
      sm: {
        maxWidth: '1024px',
      },
      md: {
        maxWidth: '1328px',
      },
      lg: {
        maxWidth: '1440px',
      },
      xl: {
        maxWidth: '1680px',
      },
      '2xl': {
        maxWidth: '1328px',
        '@5xl': {
          maxWidth: '1968px',
        },
      },
    },
    height: {
      full: {
        height: '100%',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    maxWidth: 'md',
  },
});

interface ContainerProps extends React.ComponentProps<typeof ContainerStyled> {
  /**
   * The content of the container.
   */
  children: ReactNode;
  /**
   * Additional CSS styles for the container.
   */
  css?: CSS;
}

/**
 * Container component that renders a container element with additional styling and functionality.
 * @param props - The props for the Container component.
 * @param props.children - The content of the container.
 * @param props.css - Additional CSS styles for the container.
 * @param props.size - The size of the container.
 * @param props.maxWidth - The maximum width of the container.
 * @param props.height - The height of the container.
 * @param ref - The ref for the container element.
 * @returns The rendered Container component.
 */
export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, css, ...rest }, ref) => {
    return (
      <ContainerStyled css={css} ref={ref} {...rest}>
        {children}
      </ContainerStyled>
    );
  }
);

Container.displayName = 'Container';
